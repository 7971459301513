<template>
  <v-main>
    <v-container>
      <RegistrationComponent />
    </v-container>
  </v-main>
</template>

<script>
import RegistrationComponent from "../components/Registration/RegistrationComponent.vue";
export default {
  components: {
    RegistrationComponent,
  },
};
</script>

<style>
</style>>