<template>
        <registration-main/>
</template>

<script>
import RegistrationMain from './RegistrationMain.vue';
export default {
    components: {
        RegistrationMain,
    }
}
</script>

<style>

</style>